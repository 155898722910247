import { apiPost } from '@utils/api'

const socialLoginToken = async (
  stateId,
  authCode,
  email,
  firstName,
  lastName
) => {
  const url = 'ext/n98-social-login/authenticate'
  const res = await apiPost(url, {
    stateId,
    authCode,
    email,
    firstName,
    lastName,
  })
  return res
}

export default socialLoginToken
